body::-webkit-scrollbar {
	width: 4px;
}

body::-webkit-scrollbar-track {
	background: #1e1e24;
}

body::-webkit-scrollbar-thumb {
	background: #7e8c51;
}

main {
	min-height: 80vh;
}

.myBtn {
	border: 2px solid black;
	background-color: #eeedda;
	color: black;
	padding: 14px 28px;
	font-size: 16px;
	cursor: pointer;
}

.myWarning {
	border-color: #88955e;
	color: #88955e;
}

.myWarning:hover {
	background: #88955e;
	color: white;
}

.myCalendar {
	border: 0;
	padding: 10px;
	background: #7e8c51 !important;
}

.geeks {
	cursor: pointer;
	overflow: hidden;
	margin: 0 auto;
}

.geeks img {
	width: 100%;
	transition: 0.5s all ease-in-out;
}

.geeks:hover img {
	transform: scale(1.2);
}

.myContainer {
	position: relative;
	text-align: left;
}

.centered {
	position: absolute;
	top: 40%;
	left: 40%;
	transform: translate(-50%, -50%);
}

/* #home-section-1 {
	background-image: url('public/images/home-h-1.png');
} */
